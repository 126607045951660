import React from 'react';

import Section from '../../Section';
import SectionTitle from '../../SectionTitle';

const ServicesClients = () => (
    <Section
        title={
            <SectionTitle color="white" number="01">
                For Clients
            </SectionTitle>
        }
        background="primary"
        color="white"
        hasDescription
    >
        <p>
            I am able to offer &#39;traditional&#39; Physiotherapy services, as well as an approach
            incorporating a variety of different methods gained through my extensive training and
            education. This might include;
        </p>

        <ul>
            <li>Strength & Conditioning and Resistance training programs</li>
            <li>
                Functional Movement Screen & Selective Functional Movement Assessment (FMS & SFMA)
            </li>
            <li>Functional Range Conditioning (FRC)</li>
            <li>Postural Restoration (PRI)</li>
            <li>The McGill approach to mechanical back pain</li>
            <li>Mobility and exercise technique assessments</li>
        </ul>
    </Section>
);

export default ServicesClients;
