import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import PriceListItem from './PriceListItem';

import styles from './PriceList.module.scss';

const PriceList = ({ description, data, isReverse }) => (
    <ul className={classNames(styles.root, isReverse ? styles.rootReverse : null)}>
        {data.map((item) => (
            <PriceListItem
                key={item.id}
                isReverse={isReverse}
                description={description}
                {...item}
            />
        ))}
    </ul>
);

PriceList.propTypes = {
    description: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    isReverse: PropTypes.bool,
};

PriceList.defaultProps = {
    isReverse: false,
};

export default PriceList;
