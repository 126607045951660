import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './PriceListItem.module.scss';

const PriceListItem = ({ description, duration, isReverse }) => (
    <li className={classNames(styles.root, isReverse ? styles.rootReverse : null)}>
        <span className={styles.description}>{description}</span>
        <span className={styles.duration}>{duration} minutes</span>
    </li>
);

PriceListItem.propTypes = {
    description: PropTypes.string.isRequired,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isReverse: PropTypes.bool,
};

PriceListItem.defaultProps = {
    isReverse: false,
};

export default PriceListItem;
