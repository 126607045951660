import React from 'react';

import Layout from '../components/Layout';
import Hero from '../components/Hero';

import ServicesClients from '../components/Services/ServicesClients';
import ServicesProfessionals from '../components/Services/ServicesProfessionals';
import ServicesAssessments from '../components/Services/ServicesAssessments';
import ServicesFollowUp from '../components/Services/ServicesFollowUp';

import heroImage from '../images/services/hero.jpg';

const ServicesPage = () => (
    <Layout>
        <Hero title="Services & Pricing" image={heroImage} />
        <ServicesClients />
        <ServicesProfessionals />
        <ServicesAssessments />
        <ServicesFollowUp />
    </Layout>
);

export default ServicesPage;
