import React from 'react';

import Section from '../../Section';
import SectionTitle from '../../SectionTitle';

import PriceList from '../../PriceList';

const data = [
    {
        duration: 45,
    },
    {
        duration: 60,
    },
    {
        duration: 90,
    },
];

const ServicesAssessments = () => (
    <Section
        title={
            <SectionTitle color="white" number="03">
                Assessments & Consultations
            </SectionTitle>
        }
        figure={<PriceList description="Initial Assessment" data={data} />}
        background="xdark"
        color="white"
        hasDescription
    >
        <p>
            Because the devil is in the detail with my assessment process, initial assessments may
            be longer than you would expect, or have previously experienced. When arranging your
            first session, I will make a recommendation on the appropriate assessment duration. If
            this process happens to be shorter than expected, you will be charged the cheaper rate.
        </p>
    </Section>
);

export default ServicesAssessments;
