import React from 'react';

import Section from '../../Section';
import SectionTitle from '../../SectionTitle';

import PriceList from '../../PriceList';

const data = [
    {
        duration: '30-60',
    },
    {
        duration: '60-90',
    },
];

const ServicesFollowUp = () => (
    <Section
        title={
            <SectionTitle color="white" number="04">
                Follow-Up Sessions
            </SectionTitle>
        }
        figure={<PriceList isReverse description="Follow-Up Session" data={data} />}
        background="primary"
        color="white"
        isReverse
        hasDescription
    >
        <p>
            Follow-up sessions to an assessment allow the use of &#39;hands on&#39; treatment
            techniques where necessary, as well as in-depth coaching and execution of a
            rehabilitation program. Whilst there is great value in being coached through your
            program, I understand that cost can sometimes be prohibitive. In these cases, I am more
            than happy to offer &#39;distance support&#39; through video and email feedback for as
            long as required. Please note that review and progression of rehabilitation programs
            will require a follow-up appointment.
        </p>
        <p>
            If you currently work with another therapist, personal trainer or strength &
            conditioning coach and would prefer to continue working with them during your
            rehabilitation, I am more than happy to communicate our assessment findings and my
            recommendations to them directly.
        </p>
    </Section>
);

export default ServicesFollowUp;
