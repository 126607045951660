import React from 'react';

import Section from '../../Section';
import SectionTitle from '../../SectionTitle';

const ServicesProfessionals = () => (
    <Section
        title={<SectionTitle number="02">For Health & Fitness Professionals</SectionTitle>}
        isReverse
        hasDescription
    >
        <p>
            I have an in-depth understanding of exercise, rehabilitation and resistance training
            that forms the basis of his assessment and treatment approach. I&#39;m available to
            provide workshops, mentorship and educational sessions to both health and fitness
            professionals on a variety of topics involving assessment, treatment and rehabilitation
            of a variety of topics.
        </p>
        <p>For further details - get in contact to see what I can add to your skill set.</p>
    </Section>
);

export default ServicesProfessionals;
